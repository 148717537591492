import React from "react";
import { NavLink } from "react-router-dom";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import SimilarProjects from "./../Elements/SimilarProjects";
import Banner from "./../Elements/Banner";
import ReactPlayer from "react-player";
import { db } from "./../../Utils/firebase";
import { getDatabase, ref, child, get } from "firebase/database";

var bnrimg = require("./../../images/banner/5.jpg");

class ProjectDetail extends React.Component {
  constructor(props) {
    super(props);
    const url = new URL(window.location.href);
    const params = url.searchParams;
    console.log(params);
    this.state = {
      id: params.get("id"),
      filter: params.get("filter"),
      project: {},
    };
  }

  componentDidMount() {
    // const value = queryString.parse(this.props.location.search);
    let project = {};
    const dbRef = ref(getDatabase());
    get(child(dbRef, `Projects/${this.state.filter}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          project = snapshot.val().filter((e) => e.id == this.state.id)[0];
          this.setState({ project });
          console.log(snapshot.val());
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    console.log(this.state.project);
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title="Helping you and your house become better acquainted."
            pagename="Project Detail"
            bgimage={bnrimg}
          />

          {/* SECTION CONTENT START */}
          {this.state.project && (
            <div className="section-full p-tb80 inner-page-padding">
              <div className="container">
                <div className="project-detail-outer">
                  <div className="m-b0">
                    <div className="row">
                      <div className="col-md-4 col-sm-4">
                        <div className="project-detail-pic m-b30">
                          <div className="mt-media">
                            <img src={this.state.project.img1} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="project-detail-pic m-b30">
                          <div className="mt-media">
                            <img src={this.state.project.img2} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="project-detail-pic m-b30">
                          <div className="mt-media">
                            <img src={this.state.project.img3} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="m-b30">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="product-block">
                          <div className="row">
                            {/* <div className="col-md-12 m-b10">
                            <h4 className="text-uppercase font-weight-600 m-b10">
                              Client
                            </h4>
                            <p>Studio Massimo, Italy</p>
                          </div> */}
                            <div className="col-md-12 m-b10">
                              <h4 className="text-uppercase font-weight-600 m-b10">
                                Project type
                              </h4>
                              <p>{this.state.project.Type}</p>
                            </div>
                            <div className="col-md-12 m-b10">
                              <h4 className="text-uppercase font-weight-600 m-b10">
                                Location
                              </h4>
                              <p>{this.state.project.Location}</p>
                            </div>
                            <div className="col-md-12 m-b10">
                              <h4 className="text-uppercase font-weight-600 m-b10">
                                Builtup Area
                              </h4>
                              <p>{this.state.project.size}</p>
                              <br></br>
                              <p>{this.state.project.totalsize}</p>
                            </div>
                            <div className="col-md-12 m-b10">
                              <h4 className="text-uppercase font-weight-600 m-b10">
                                Year of Completion
                              </h4>
                              <p>{this.state.project.Year}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="mt-box">
                          <div className="mt-thum-bx mt-img-overlay1 mt-img-effect yt-thum-box">
                            <img src={this.state.project.img4} alt="" />
                            {this.state.project.video ? (
                              <button
                                type="button"
                                className="play-now"
                                // data-toggle="modal"
                                // data-target="#myModal3"
                              >
                                <a
                                  href={this.state.project.yturl}
                                  target="_blank"
                                >
                                  <i className="icon fa fa-play" />
                                  <span className="ripple" />
                                </a>
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="project-detail-containt">
                    <div className="bg-white text-black">
                      <h3 className="font-weight-600">
                        {this.state.project.Name}
                      </h3>
                      <p style={{ textAlign: "justify" }}>
                        {" "}
                        {this.state.project.summary1}
                      </p>
                      <h4 className="text-uppercase font-weight-600 m-b10">
                        {this.state.project.amenities ? "Amenities" : ""}
                      </h4>
                      <ul>
                        {this.state.project.amenities &&
                          this.state.project.amenities.split(",").map((e) => {
                            return <li style={{ padding: "8px" }}>{e}</li>;
                          })}
                      </ul>
                      {this.state.project.report ? (
                        <a
                          href={this.state.project.report}
                          download="MyExampleDoc"
                          target="_blank"
                        >
                          <button className="site-button-secondry btn-half">
                            Download Brochure
                          </button>
                        </a>
                      ) : (
                        " "
                      )}

                      {/* <p style ={{textAlign:'justify'}}>{this.state.project.summary2} </p> */}
                      <div className="m-b0">
                        <div className="mt-divider divider-1px  bg-black">
                          <i className="icon-dot c-square" />
                        </div>
                      </div>
                      <ul className="social-icons social-square social-darkest m-b0">
                        <li>
                          <NavLink to={"#"} className="fa fa-facebook" />
                        </li>
                        <li>
                          <NavLink to={"#"} className="fa fa-twitter" />
                        </li>
                        <li>
                          <NavLink to={"#"} className="fa fa-linkedin" />
                        </li>
                        <li>
                          <NavLink to={"#"} className="fa fa-rss" />
                        </li>
                        <li>
                          <NavLink to={"#"} className="fa fa-youtube" />
                        </li>
                        <li>
                          <NavLink to={"#"} className="fa fa-instagram" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* SECTION CONTENT END  */}
          {/* <SimilarProjects/> */}
        </div>

        {/* {this.state.project && (
          <div className="modal fade" id="myModal3" role="dialog">
            <div className="modal-dialog">
              <div className="modal-content">
                <ReactPlayer url={this.state.project.yturl} />
              </div>
            </div>
          </div>
        )} */}

        <Footer />
      </>
    );
  }
}

export default ProjectDetail;
