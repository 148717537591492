import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import GoogleMapReact from "google-map-react";
import emailjs from "@emailjs/browser";

var bnrimg = require("./../../images/banner/4.jpg");

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "", number: "", message: "" };
    this.form = React.createRef()
  }

  sendEmail = (e) => {
    e.preventDefault();
    console.log(this.state, this.form.current)
    emailjs
      .sendForm(
        "service_x4ahz3g",
        "template_2yoflyc",
        this.form.current,
        "T9Q383y0JLra0SGoA"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  render() {
    const defaultProps = {
      center: {
        lat: 34.07328,
        lng: -118.25141,
      },
      zoom: 12,
    };
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title="Putting a plan to action, to assure your satisfaction!"
            pagename="Contact Us"
            bgimage={bnrimg}
          />

          {/* SECTION CONTENTG START */}
          <div className="section-full p-tb80 inner-page-padding">
            {/* LOCATION BLOCK*/}
            <div className="container">
              {/* GOOGLE MAP & CONTACT FORM */}
              <div className="section-content">
                {/* CONTACT FORM*/}
                <div className="row">
                  <div className="col-md-8 col-sm-6">
                    <form
                      className="contact-form cons-contact-form"
                      // method="post"
                      // action="form-handler.php"
                      onSubmit={this.sendEmail}
                      ref = {this.form}
                    >
                      <div className="contact-one m-b30">
                        {/* TITLE START */}
                        <div className="section-head">
                          <div className="mt-separator-outer separator-left">
                            <div className="mt-separator">
                              <h2 className="text-uppercase sep-line-one ">
                                <span className="font-weight-300 text-primary">
                                  Get
                                </span>{" "}
                                In touch
                              </h2>
                            </div>
                          </div>
                        </div>
                        {/* TITLE END */}
                        <div className="form-group">
                          <input
                            onChange={(e) => {
                              this.setState({ name: e.target.value });
                            }}
                            name="name"
                            type="text"
                            required
                            className="form-control"
                            placeholder="Name"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            onChange={(e) => {
                              this.setState({ number: e.target.value });
                            }}
                            name="number"
                            type="number"
                            className="form-control"
                            required
                            placeholder="Phone Number"
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            onChange={(e) => {
                              this.setState({ message: e.target.value });
                            }}
                            name="message"
                            rows={4}
                            className="form-control "
                            required
                            placeholder="Message"
                            defaultValue={""}
                          />
                        </div>
                        <div className="text-right">
                          <button
                            name="submit"
                            type="submit"
                            value="Submit"
                            className="site-button btn-effect"
                          >
                            submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="contact-info m-b30">
                      {/* TITLE START */}
                      <div className="section-head">
                        <div className="mt-separator-outer separator-left">
                          <div className="mt-separator">
                            <h2 className="text-uppercase sep-line-one ">
                              <span className="font-weight-300 text-primary">
                                Contact
                              </span>{" "}
                              Info
                            </h2>
                          </div>
                        </div>
                      </div>
                      {/* TITLE END */}
                      <div className="bg-dark p-a20 text-white">
                        <div className="mt-icon-box-wraper left p-b40">
                          <div className="icon-xs">
                            <i className="fa fa-phone" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t0 font-weight-500">
                              Phone number
                            </h5>
                            <p>(+91)-93636-08500</p>
                          </div>
                        </div>
                        <div className="mt-icon-box-wraper left p-b40">
                          <div className="icon-xs">
                            <i className="fa fa-envelope" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t0 font-weight-500">
                              Email address
                            </h5>
                            <p>info@nvsconstruction.in <br></br>
                            marketing@nvsconstruction.in</p>
                          </div>
                        </div>
                        <div className="mt-icon-box-wraper left">
                          <div className="icon-xs">
                            <i className="fa fa-map-marker" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t0 font-weight-500">
                              Address info
                            </h5>
                            <p>
                              39, Sai Shrishti Apartment - II, Anna nagar,
                              Peelamadu.<br></br> Coimbatore- 641 004.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>

        <Footer />
      </>
    );
  }
}

export default ContactUs;
