import React from "react";
import { NavLink } from "react-router-dom";
import { db } from "./../../Utils/firebase";
import { getDatabase, ref, child, get } from "firebase/database";

const filters = [
  { label: "All", filter: "All" },
  { label: "Completed", filter: "Completed" },
  { label: "Under Construction", filter: "Ongoing" },
  { label: "Launching Soon", filter: "Upcoming" },
  // { label: "Interiors", filter: ".cat-4" },
  // { label: "Consulting", filter: ".cat-5" },
];

const projects = [
  {
    image: require("./../../images/projects/portrait/pic1.jpg"),
    title: "Chair Furniture",
    address: "Muscat, Sultanate of Oman",
    width: "col-md-3",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/portrait/pic2.jpg"),
    title: "Furniture",
    address: "North House",
    width: "col-md-3",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/portrait/pic3.jpg"),
    title: "Living room",
    address: "Aqaba, Jordan",
    width: "col-md-3",
    filter: "cat-6",
  },
  {
    image: require("./../../images/projects/portrait/pic4.jpg"),
    title: "Dream Workspace",
    address: "Perth, Australia",
    width: "col-md-3",
    filter: "cat-6",
  },
  {
    image: require("./../../images/projects/portrait/pic5.jpg"),
    title: "Decore",
    address: "Aqaba, Jordan",
    width: "col-md-3",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/portrait/pic6.jpg"),
    title: "Modern Decoration",
    address: "Muscat, Sultanate of Oman",
    width: "col-md-3",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/portrait/pic7.jpg"),
    title: "Bathroom",
    address: "North House",
    width: "col-md-3",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/pic-l-5.jpg"),
    title: "Lamp decoration",
    address: "Aqaba, Jordan",
    width: "col-md-6",
    filter: "cat-6",
  },
  {
    image: require("./../../images/projects/pic-1.jpg"),
    title: "Building house",
    address: "Ultanate of Oman",
    width: "col-md-3",
    filter: "cat-5",
  },
  {
    image: require("./../../images/projects/pic-2.jpg"),
    title: "Spa residence",
    address: "Perth, Australia",
    width: "col-md-3",
    filter: "cat-4",
  },
];

class OurProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = { projects: [], selectedFilter: "All" };
  }

  componentDidMount() {
    let projects = [];
    const dbRef = ref(getDatabase());
    get(child(dbRef, `Projects`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          let response = snapshot.val();
          Object.keys(response).forEach((key) => {
            response[key].forEach((project) => {
              projects.push({ ...project, filter: key });
            });
          });
          console.log(projects);
          this.setState({ projects });
          console.log(snapshot.val());
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });

    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/masonary.js");
  }

  render() {
    let filteredProjects =
      this.state.selectedFilter != "All"
        ? this.state.projects.filter(
            (e) => e.filter == this.state.selectedFilter
          )
        : this.state.projects;
    console.log(filteredProjects);
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b30 square_shape2 ">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="mt-separator-outer separator-left">
                <div className="mt-separator">
                  <h2 className="text-uppercase sep-line-one ">
                    <span className="font-weight-300 text-primary">Our</span>{" "}
                    Projects
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content">
              <div className="portfolio-wrap row mfp-gallery product-stamp clearfix">
                {/* COLUMNS 1 */}
                <div className="stamp col-md-3 col-sm-6 m-b30">
                  <div className="bg-gray p-a30">
                    <div className="filter-wrap">
                      <ul className="filter-navigation text-uppercase">
                        {filters.map((item, index) => (
                          <li
                            key={index}
                            className={
                              this.state.selectedFilter == item.filter
                                ? "active-filter"
                                : ""
                            }
                            style={{ transition: "none" }}
                            onClick={() => {
                              console.log(item.filter);
                              this.setState({ selectedFilter: item.filter });
                            }}
                          >
                            <NavLink to={"#"}>{item.label}</NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* COLUMNS 2 */}
                {filteredProjects.map((item, index) => (
                  <div
                    key={index}
                    className={`${item.filter} masonry-item ${item.width} col-sm-6 m-b30`}
                  >
                    <div className="mt-box   image-hover-block">
                      <div className="mt-thum-bx">
                        <img
                          src={
                            item.url
                              ? item.url
                              : require("./../../images/projects/portrait/pic5.jpg")
                          }
                          alt=""
                        />
                      </div>
                      <div className="mt-info  p-t20 text-white">
                        <h4 className="mt-tilte m-b10 m-t0">{item.Name}</h4>
                        <p className="m-b0">{item.address}</p>
                      </div>
                      <NavLink
                        to={`/project-detail?id=${item.id}&filter=${item.filter}`}
                      ></NavLink>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OurProject;
