import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const testimonials = [
    {
        image: require('./../../images/testimonials/pic1.jpg'),
        reviewername: 'Mrs. Sabithabai',
        position: 'Pollachi',
        review: 'NVS Construction exceeded our expectations with their attention to detail and on-time delivery. Highly recommended!'
    },
    {
        image: require('./../../images/testimonials/pic2.jpg'),
        reviewername: 'Mr. P. Bagavath Singh',
        position: 'Coimbatore',
        review: 'They deliver the best quality building construction that follows the norms needed according regulations in the construction market'
    },
    {
        image: require('./../../images/testimonials/pic3.jpg'),
        reviewername: 'Mrs. Nithya',
        position: 'Salem',
        review: 'NVS Construction\'s work was exceptional; they delivered our dream home with precision and professionalism.'
    },
    {
        image: require('./../../images/testimonials/pic4.jpg'),
        reviewername: 'Ms. A. Ilayakiya',
        position: 'Coimbatore',
        review: 'Excellent Customer support! They were attentive, responsive, and went above and beyond to address our needs. '
    },
]

var bnr1 = require('./../../images/background/bg6.jpg');

class Testimonials extends React.Component {
    render() {

        const options = {
            loop:true,
            autoplay:true,
            margin:30,
            nav:false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                991:{
                    items:2
                }
            }
        };

        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b50 square_shape2 bg-cover" style={{backgroundImage:"url(" + bnr1 + ")"}}>
                    <div className="container">
                        <div className="section-content">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="mt-separator-outer separator-center">
                                    <div className="mt-separator">
                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Client</span> Testimonials</h2>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            {/* TESTIMONIAL START */}
                            <OwlCarousel className="owl-carousel testimonial-home" {...options}>
                            
                                {testimonials.map((item, index) => (
                                    <div key={index} className="item">
                                        <div className="testimonial-2 m-a30 hover-animation-1">
                                            <div className=" block-shadow bg-white p-a30">
                                                <div className="testimonial-detail clearfix">
                                                    {/* <div className="testimonial-pic radius shadow scale-in-center"><img src={item.image} width={100} height={100} alt=""/></div> */}
                                                    <h4 className="testimonial-name m-b5">{item.reviewername} -</h4>
                                                    <span className="testimonial-position">{item.position}</span>
                                                </div>
                                                <div className="testimonial-text">
                                                    <span className="fa fa-quote-right" />
                                                    <p> {item.review}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>    
                               
                            
                        </div>
                    </div>
                    
                </div>
            </>
        );
    }
};

export default Testimonials;