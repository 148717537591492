import React from "react";
import { NavLink } from "react-router-dom";

class AboutCompany extends React.Component {
  render() {
    return (
      <>
        <div className="section-full p-t80 bg-white">
          <div className="container">
            <div className="section-content ">
              <div className="m-service-containt text-black">
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <div className="service-about-left">
                      <div className="mt-media">
                        <img src={require("./../../images/s-1.jpg")} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 col-sm-12">
                    <div className="service-about-right m-b30">
                      <h3 className="m-t0">
                        In our work we have pride, quality is what we provide.
                      </h3>
                      <p>
                        At NVS Construction, pride is inherent in every aspect
                        of our work, and quality is the foundation of our
                        services. With a deep sense of commitment to excellence,
                        we approach each project with dedication and passion.
                        Our team of skilled professionals is driven by a
                        collective desire to deliver the highest standards of
                        construction and craftsmanship. From the initial design
                        phase to the final execution, we ensure that attention
                        to detail is given utmost importance, resulting in
                        outstanding outcomes.{" "}
                      </p>
                      <div className="call-for-quote-outer">
                        <div className="call-quote">
                          <span>Call For a Quote:</span>
                          <h4>(+91)-93636-08500</h4>
                        </div>
                        {/* <div className="call-estimate bg-dark">
                                                <NavLink to={"#"} className="site-button-secondry btn-effect">Online Estimate Form</NavLink>
                                                </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AboutCompany;
