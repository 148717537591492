import React from 'react';

var img1 = require('./../../images/background/bg-6.png');
var img2 = require('./../../images/background/line.png');

class AboutSummary extends React.Component {
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('./assets/js/masonary.js');
      
    };
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b80 bg-dark bg-repeat square_shape2 inner-page-padding" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-left">
                                <div className="mt-separator text-white">
                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Project</span> Highlights</h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content our-story">
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../../images/gallery/portrait/pic4.jpg')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover" style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        <h4 className="text-uppercase m-b20">2018-19</h4>
                                        <h4 className="m-b20">Sai Shrishti Apartment - I </h4>
                                        <p>Introducing Sai Shrishti Apartments - I, an exclusive project situated in Anna Nagar, Peelamedu. It marks the inception of our apartment construction ventures, spanning an area of 9400 sq ft across three floors. Each floor features two meticulously designed 2BHK apartments, each spanning 1000 sq ft, offering modern and comfortable living spaces for discerning residents.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../../images/gallery/portrait/pic7.jpg')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover"  style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        <h4 className="text-uppercase m-b20">2020-21</h4>
                                        <h4 className="m-b20">Sai Shrishti Apartment - II </h4>
                                        <p>Building upon the success of Sai Shrishti Apartments - I, we proudly present Sai Shrishti Apartments - II, completed in 2021. Situated in the locality of Anna Nagar, Peelamedu, this project spans an area of 8520 sq ft, spread across three floors. Each floor features two thoughtfully designed 2BHK apartments, generously sized at 1115 sq ft each, providing residents with contemporary and spacious living spaces.</p>
                                    </div>
                                </div>
                            </div>
                             <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../../images/gallery/portrait/pic6.jpg')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover"  style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        <h4 className="text-uppercase m-b20">2023-24</h4>
                                        <h4 className="m-b20">Sai Viruksham</h4>
                                        <p>Sai Viruksham, a recent project by NVS Construction, stands as a testament to modern architectural excellence. Nestled within the serene surroundings of Indira Gardens, it offers more than just a living space. With meticulous attention to detail, the apartments boast contemporary design elements and top-notch amenities, ensuring residents experience the epitome of urban living.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../../images/gallery/portrait/pic5.jpg')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover"  style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        <h4 className="text-uppercase m-b20">2017-18</h4>
                                        <h4 className="m-b20">Museum Expansion</h4>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. remaining essentially unchanged. </p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default AboutSummary;