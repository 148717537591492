// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBk6xNeK0a_DLUQ0vh4sEBpumO3SYW03pw",
  authDomain: "nvs-construction.firebaseapp.com",
  projectId: "nvs-construction",
  storageBucket: "nvs-construction.appspot.com",
  messagingSenderId: "1020542914585",
  appId: "1:1020542914585:web:140806307532833bc45a88",
  measurementId: "G-6KHZ5PWX5C",
  databaseURL : "https://nvs-construction-default-rtdb.firebaseio.com"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getDatabase(app)
