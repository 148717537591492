import React from "react";
import { NavLink } from "react-router-dom";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import { db } from "./../../Utils/firebase";
import { getDatabase, ref, child, get } from "firebase/database";
import "./custom.css"

var bnrimg = require("./../../images/banner/2.jpg");

const filters = [
  { label: "All", filter: "All" },
  { label: "Completed", filter: "Completed" },
  { label: "Under Construction", filter: "Ongoing" },
  { label: "Upcoming", filter: "Upcoming" },
  // { label: "Interiors", filter: ".cat-4" },
  // { label: "Consulting", filter: ".cat-5" },
];

const projects = [
  {
    thumb: require("./../../images/projects/portrait/pic1.jpg"),
    image: require("./../../images/projects/portrait/pic4.jpg"),
    title: "Life style building",
    description: `We are so busy watching out for what's just ahead of us that we don't take the time to enjoy where we are.`,
    filter: "cat-1",
  },
  {
    thumb: require("./../../images/projects/portrait/pic2.jpg"),
    image: require("./../../images/projects/portrait/pic9.jpg"),
    title: "Central City Building",
    description: `We are so busy watching out for what's just ahead of us that we don't take the time to enjoy where we are.`,
    filter: "cat-2",
  },
  {
    thumb: require("./../../images/projects/portrait/pic3.jpg"),
    image: require("./../../images/projects/portrait/pic6.jpg"),
    title: "Nathan Brooke House",
    description: `We are so busy watching out for what's just ahead of us that we don't take the time to enjoy where we are.`,
    filter: "cat-3",
  },
  {
    thumb: require("./../../images/projects/portrait/pic4.jpg"),
    image: require("./../../images/projects/portrait/pic5.jpg"),
    title: "Metropolitan Museum",
    description: `We are so busy watching out for what's just ahead of us that we don't take the time to enjoy where we are.`,
    filter: "cat-4",
  },
  {
    thumb: require("./../../images/projects/portrait/pic5.jpg"),
    image: require("./../../images/projects/portrait/pic7.jpg"),
    title: "Art Family Residence",
    description: `We are so busy watching out for what's just ahead of us that we don't take the time to enjoy where we are.`,
    filter: "cat-5",
  },
  {
    thumb: require("./../../images/projects/portrait/pic6.jpg"),
    image: require("./../../images/projects/portrait/pic8.jpg"),
    title: "Office Partition Walls",
    description: `We are so busy watching out for what's just ahead of us that we don't take the time to enjoy where we are.`,
    filter: "cat-4",
  },
  {
    thumb: require("./../../images/projects/portrait/pic7.jpg"),
    image: require("./../../images/projects/portrait/pic10.jpg"),
    title: "Glass Office Screen",
    description: `We are so busy watching out for what's just ahead of us that we don't take the time to enjoy where we are.`,
    filter: "cat-3",
  },
  {
    thumb: require("./../../images/projects/portrait/pic8.jpg"),
    image: require("./../../images/projects/portrait/pic1.jpg"),
    title: "State Hermitage Museum",
    description: `We are so busy watching out for what's just ahead of us that we don't take the time to enjoy where we are.`,
    filter: "cat-2",
  },
  {
    thumb: require("./../../images/projects/portrait/pic9.jpg"),
    image: require("./../../images/projects/portrait/pic5.jpg"),
    title: "Central Florida University",
    description: `We are so busy watching out for what's just ahead of us that we don't take the time to enjoy where we are.`,
    filter: "cat-1",
  },
];

class ProjectGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = { projects: [], selectedFilter: "All" };
  }
  componentDidMount() {
    let projects = [];
    const dbRef = ref(getDatabase());
    get(child(dbRef, `Projects`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          let response = snapshot.val();
          Object.keys(response).forEach((key) => {
            response[key].forEach((project) => {
              projects.push({ ...project, filter: key });
            });
          });
          console.log(projects);
          this.setState({ projects });
          console.log(snapshot.val());
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });

    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/masonary.js");
  }

  setFilter = () => {
    console.log("test");
  };

  render() {
    let filteredProjects =
      this.state.selectedFilter != "All"
        ? this.state.projects.filter(
            (e) => e.filter == this.state.selectedFilter
          )
        : this.state.projects;
    console.log(this.state.selectedFilter)
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title="Always dedicated and devoted"
            pagename="Project Grid"
            bgimage={bnrimg}
          />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 inner-page-padding">
            <div className="container">
              {/* PAGINATION START */}
              <div className="filter-wrap p-b30 text-center">
                <ul className="filter-navigation inline-navigation link-style  text-uppercase masonary-filter custom-ul" >

                  {filters.map((item, index) => (
                    <li
                      key={index}
                      className={
                        this.state.selectedFilter == item.filter ? "active-filter" : ""
                      }
                      style = {{transition:'none'}}
                      onClick={() => {
                        this.setState({ selectedFilter: item.filter });
                      }}
                    >
                      <NavLink to={"#"}>{item.label}</NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              {/* PAGINATION END */}
              {/* GALLERY CONTENT START */}
              <div className="portfolio-wrap mfp-gallery work-grid">
                {filteredProjects.map((item, index) => (
                  <div
                    key={index}
                    className={`${item.filter} masonry-item m-b30 align-grid`}
                    style={{padding:'8px'}}
                  >
                    <div className="image-effect-two hover-shadow">
                      <img src={item.url ? item.url :require("./../../images/projects/portrait/pic5.jpg") } alt="" />
                      <div className="figcaption">
                        <h4 className="mt-tilte">{item.Name}</h4>
                        <p>
                          {item.description
                            ? item.description
                            : "Sample Inshjcbjs"}
                        </p>
                        <NavLink
                          to={`/project-detail?id=${item.id}&filter=${item.filter}` }
                          className="read-more site-button btn-effect"
                        >
                          Read More
                        </NavLink>
                        {/* <a className="mfp-link" href={item.image}>
                          <i className="fa fa-arrows-alt" />
                        </a> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* GALLERY CONTENT END */}
              {/* <div className="m-t50 text-center">
                <NavLink to={"#"} className="site-button btn-effect">
                  Load More
                </NavLink>
              </div> */}
            </div>
          </div>
          {/* SECTION CONTENT END  */}
        </div>

        <Footer />
      </>
    );
  }
}

export default ProjectGrid;
